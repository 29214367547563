import { defineStore } from "pinia";
import { usePOSStore } from "@/views/pos/stores/pos";
import { useCartStore } from "@/views/pos/stores/cart";
import { useInvoicesStore } from "@/views/pos/stores/invoices";
import posConfig from "@/views/pos/config/pos"
import { useAppStore } from "@/stores/app";
import ApiService from "@/core/services/apiServicev3";
import { useInvoiceStore } from "@/stores/sales/invoices";
import { useSalesStore } from "@/stores/sales/sales";
import { useContentModalsStore } from "@/stores/content/modals";

interface RootState {
  payments: any
  current: any
  paymentValue: any | null
  paymentStatus: null | any
  isLoading: boolean
  isPaying: boolean
  isCancelling: boolean
  isCancelled: boolean
  isSynching: boolean
  isReload: number
  pinTerminal: any
}

export const usePaymentsStore = defineStore({
  id: "paymentsStore",
  state: () =>
  ({
    payments: null,
    current: null,
    paymentValue: null,
    paymentStatus: null,
    pinTerminal: {
      isLoading: false,
      isPaying: false,
      isCancelling: false,
      isFinished: false,
      isError: {
        state: false,
        errorMessage: null,
      }
    },
    isLoading: false,
    isCancelling: false,
    isCancelled: false,
    isSynching: false,
    isReload: 1,
  } as RootState),
  getters: {
    getAllPayments(state) {
      return state.payments;
    },
    currentPaymentId(state) {
      return state.current?.id;
    },
    currentPaymentStatus(state) {
      return state.current?.payment_status;
    },
    reloadStatus(state) {
      return state.isReload;
    },
    currentPaymentTotalAmount(state) {
      return state.current?.amount_total
    },
    cancelStatus(state) {
      return state.isCancelled;
    },
    currentPaymentValue(state) {
      return state.paymentValue;
    },
    isTerminalPaying(state) {
      return state.pinTerminal.isPaying;
    },
    isPaymentSuccesfull(state) {
      return state.current?.payment_status === 'paid' || false;
    }
  },
  actions: {
    async processPayment() {
      const cartStore = useCartStore();
      const posStore = usePOSStore();
      const invoiceStore = useInvoicesStore();
      const contentModalStore = useContentModalsStore();

      this.isLoading = true;
      this.pinTerminal.isFinished = false;
      this.pinTerminal.isError = false;
      // cartStore.isCompleted = false;

      const paymentValue = this.currentPaymentValue <= cartStore.outstandingAmount ? this.currentPaymentValue : cartStore.outstandingAmount
      try {
        // if (cartStore.saleStatus === 'concept') {
        //   await cartStore.updateSale({ sale_status: 'pending' });
        // }

        if (cartStore.selectedPaymentMethod === "onAccount") {
          await this.processOnAccountPayment();
        } else if (cartStore.selectedPaymentMethod === "pin" || cartStore.selectedPaymentMethod === "cash") {
          this.pinTerminal.isLoading = true;
          const payment = await this.createPayment(paymentValue);

          if (payment.item_id) {
            this.pinTerminal.isLoading = false;
            let isFullFilled = false;

            if (cartStore.selectedPaymentMethod === 'pin') {
              await this.validatePayment(payment.item_id);

              if (this.current.payment_status === 'paid' && cartStore.outstandingAmount == this.currentPaymentValue) {
                invoiceStore.current = this.current.invoices[0].invoice;
                isFullFilled = true;
              }

            } else if (cartStore.selectedPaymentMethod === 'cash') {
              if (this.paymentValue >= cartStore.outstandingAmount) {
                invoiceStore.current = payment.invoice;
                isFullFilled = true;
              }



              // await this.processPinOrCashPayment(this.currentPaymentStatus, paymentValue);
              // await posStore.validateDocument(this.current.invoices[0].id, 'sales_invoices');
              // await invoiceStore.invoice(this.current.invoices[0].id);
            }

            if (isFullFilled) {
              cartStore.isCompleted = true;
              posStore.initAutoPrint();
            } else {
              contentModalStore.closeModal('es_modal_pay_create')
            }
          }
        }
      }
      finally {
        // await this.finalizePayment(this.currentPaymentStatus);
        // await this.finalizeOrder(paymentId);
        await cartStore.initCartSession(cartStore.saleId, false);
        this.isLoading = false;
      }
    },
    async finalizeOrder(paymentId: number) {
      const saleStore = useSalesStore();
      const cartStore = useCartStore();
      const invoiceStore = useInvoicesStore();

      const sale = await saleStore.validateSale(cartStore.saleId, 'completed');

      await this.validatePayment(paymentId);

      await invoiceStore.invoice(this.current.invoices[0].id);


      //
    },
    async processOnAccountPayment() {
      const cartStore = useCartStore();
      const invoicesStore = useInvoiceStore();
      const appStore = useAppStore();
      const posInvoiceStore = useInvoicesStore();

      await cartStore.saleData(cartStore.saleId);
      const unbilledLines = await this.unbilledLines();

      if (unbilledLines.length > 0) {
        cartStore.isCompleted = true;
        const { status: invoiceStatus, data: invoice } = await invoicesStore.createInvoice(cartStore.saleId, unbilledLines);

        if (invoiceStatus === 'success') {
          const invoiceDoc = await appStore.validateDocument(invoice.item_id, 'sales_invoices');
          posInvoiceStore.current = invoiceDoc;
        }
      }

      cartStore.updateSale({ on_account: 1 });
    },
    async processPinOrCashPayment(currentPaymentStatus, currentPaymentValue) {
      const cartStore = useCartStore();
      const posStore = usePOSStore();
      const invoiceStore = useInvoicesStore();

      if (currentPaymentStatus === 'paid' || currentPaymentStatus === null) {
        await cartStore.saleData(cartStore.saleId);
        if (cartStore.outstandingAmount === 0 || cartStore.outstandingAmount - currentPaymentValue === 0) {
          const unbilledLines = await this.unbilledLines();

          if (unbilledLines.length > 0) {
            const { data } = await this.createInvoice(unbilledLines);
            await posStore.validateDocument(data.id, 'sales_invoices');
            await invoiceStore.invoice(data.id);
          }
          await this.linkInvoicesPayments(cartStore.saleId);

          cartStore.isCompleted = true;
        }
      }
    },
    async finalizePayment(currentPaymentStatus) {
      const cartStore = useCartStore();

      await cartStore.saleData(cartStore.saleId);
      await cartStore.calculateTotals();

      if (cartStore.outstandingAmount > 0) {
        // await cartStore.processPay();
        // await this.cancelPayment();
      } else {
        cartStore.isCompleted = true;
      }
    },
    async processCancelPayment() {
      if (this.pinTerminal.isPaying) {
        await this.cancelPinSession();
      } else {
        // this.closeModal();
      }
    },
    async cancelPayment() {
      this.isCancelled = true;
      const cartStore = useCartStore();

      if (this.currentPaymentStatus === "pending" && cartStore.selectedPaymentMethod === "pin") {
        try {
          await this.cancelPinSession();
        }
        catch (error) {
          //
        }
      } else {
        // this.closeModal();
        cartStore.paymentMethod = null;
        // cartStore.isCompleted = false;
      }

      this.isCancelled = false;
      this.current = null;
    },
    async createInvoicePayment(paymentId: number, invoiceId: number, totalPaymentValue: string, outstandingInvoiceValue: string) {
      try {
        const payload = {
          payment_id: paymentId,
          invoice_id: invoiceId,
          amount: totalPaymentValue
        }
        const { data } = await ApiService.post("apiUrl", "/sales/invoices/payment", payload);

        return data;
      }
      finally {
        //
      }
    },
    async linkInvoicesPayments(saleId: number) {
      const invoicesStore = useInvoicesStore();
      const posStore = usePOSStore();

      await Promise.all([
        invoicesStore.invoices(saleId),
        this.allPayments()
      ])

      const allInvoicesNotPaid = invoicesStore.allInvoices.filter(invoice => invoice.invoice_status !== 'paid');
      const allPaymentsNotFullyShared = this.getAllPayments.filter(payment => payment.amount_outstanding != "0.00");

      for (const invoice of allInvoicesNotPaid) {
        let outstandingInvoiceAmount = Number(invoice.amount_outstanding)

        for (const payment of allPaymentsNotFullyShared) {
          if (outstandingInvoiceAmount <= 0) {
            break;
          }

          const outstandingTransactionAmount = Number(payment.amount_outstanding);
          const appliedAmount = Math.min(outstandingInvoiceAmount, outstandingTransactionAmount);

          this.createInvoicePayment(payment.id, invoice.id, appliedAmount.toFixed(2).toString(), appliedAmount.toFixed(2).toString());

          // await posStore.val(task.task_id);
          outstandingInvoiceAmount -= appliedAmount;
        }
      }
    },
    unbilledLines() {
      const cartStore = useCartStore();

      const object = [] as any
      const saleLines = cartStore.getCartLines;

      for (const line of saleLines) {
        const invoiceableQuantity = line.quantity - line.billed_quantity;
        if (invoiceableQuantity > 0) {
          object.push({ id: line.id, quantity: invoiceableQuantity })
          const cartLine = cartStore.lines.find(cartLine => cartLine.lineId === line.id);
          // cartLine.status = 2
        }
      }

      return object;
    },
    async createInvoice(unbilledLines) {
      const cartStore = useCartStore();

      const payload = {
        sale_id: cartStore.saleId,
        sale_lines: unbilledLines
      }

      try {
        const { data } = await ApiService.post('apiUrl', "/sales/invoice", payload)
        return data;
      }
      finally {
        //
      }
    },
    async createPayment(paymentValue: string) {
      const posStore = usePOSStore();
      const cartStore = useCartStore();

      // Only create a transaction if the value is greater than 0.
      if (paymentValue > "0.00") {
        const payload = {
          payment_method: cartStore.selectedPaymentMethod,
          sale_id: cartStore.saleId,
          pos_id: posStore.currentPOSId,
          amount_total: paymentValue
        }

        if (cartStore.selectedPaymentMethod === "cash") {
          payload['payment_status'] = 'paid';
        } else if (cartStore.selectedPaymentMethod === "pin") {
          payload['payment_status'] = 'pending';
          payload['payment_provider'] = 'Adyen';
          payload['connection_id'] = posStore.pinTerminalId;
        } else if (cartStore.selectedPaymentMethod === "onAccount") {
          payload['payment_status'] = 'pending';
        }

        try {
          const { data } = await ApiService.post("apiUrl", "/sales/payment", payload)
          return data;
        }
        finally {
          //
        }
      } else {
        return null
      }
    },
    // closeModal() {
    //   const posStore = usePOSStore();
    //   const modalId = 'es_pos_pay';
    //   const modal = document.getElementById(modalId);

    //   if (!modal) {
    //     return;
    //   }

    //   const instance = Modal.getOrCreateInstance(modal);
    //   instance.hide();

    //   posStore.resetMenuAction();
    // },
    async validatePayment(paymentId) {
      const appStore = useAppStore();
      const cartStore = useCartStore();
      if (paymentId) {
        return new Promise<void>((resolve, reject) => {
          let amountChecks = 0;
          let documentChecks = 0;
          const interval = setInterval(async () => {
            amountChecks += 1
            if (amountChecks === posConfig.validatePaymentTotalChecks) {

              clearInterval(interval);
              resolve();
            } else {
              try {
                await ApiService.get('apiUrlSearch', `/indexes/sales_payments/documents/${paymentId}`)
                  .then(({ data }) => {
                    this.current = data;
                    documentChecks++

                    if (cartStore.selectedPaymentMethod === "pin") {
                      this.pinTerminal.isPaying = true;
                    }

                    if (this.current.payment_status == 'paid' || this.current.payment_status == 'canceled' || this.current.payment_status == 'expired' || this.current.payment_status == 'failed') {
                      if (this.current.payment_status === 'canceled') {
                        let errorMessage;

                        if (documentChecks < 4) {
                          errorMessage = "Terminal_no_response"
                        } else {
                          errorMessage = "Terminal_session_cancelled"
                        }

                        this.pinTerminal.isError = {
                          state: true,
                          errorMessage: errorMessage
                        }
                      }

                      this.pinTerminal.isPaying = false;
                      this.pinTerminal.isCancelling = false;
                      // this.pinTerminal.isFinished = true;
                      if (this.current.payment_status === 'paid' && cartStore.selectedPaymentMethod === 'pin') {
                        this.pinTerminal.isFinished = true;
                      }
                      clearInterval(interval);
                      resolve();
                    }
                  })
              } catch (error: any) {
                if (error && error.response.status === 404) {
                  return;
                }
                clearInterval(interval);
                reject(error);
              }
            }
          }, posConfig.validatePaymentCheckTimer);
        });
      } else {
        return null;
      }
    },
    async cancelPinSession() {
      try {
        this.pinTerminal.isCancelling = true;
        await ApiService.post("apiUrl", "/sales/payment/cancel-pin", { id: this.currentPaymentId })
      }
      catch (error) {
        this.pinTerminal.isCancelling = false;
      }
      finally {
        this.pinTerminal.isPaying = false;
        this.pinTerminal.isCancelling = false;
      }
    },
    async allPayments() {
      const appStore = useAppStore();
      const cartStore = useCartStore();
      try {

        const payload = {
          filter: [[`sale_id=${cartStore.saleId}`]],
          sort: [
            "id:desc"
          ],
          matchingStrategy: "all",
          facets: ["*"],
          offset: 0,
        }

        await ApiService.post('apiUrlSearch', `/indexes/sales_payments/search`, payload)
          .then(({ data }) => {
            this.payments = data.hits;
          })
      }
      finally {
        //
      }
    }
  }
});